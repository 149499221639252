import React, { useState, useEffect } from "react";
import "./SignIn.css";
//ant design
import { Spin } from "antd";
import { Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { Popover } from "antd";
//
import { authSignIn } from "../authentication";
import { useNavigate, useParams } from "react-router-dom";
//
import { getTenant } from "../../../API/tenantAPI";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import logo from "../../../assest/habsynclogo.jpg";
//
const SignIn = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  const [logoLoading, setLogoLoading] = React.useState<boolean>(false);
  //
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  //
  //let { tenantId } = useParams();
  let { tenantUUID } = useParams();
  const [TenantName, setTenantName] = useState("");
  const [TenantLogo, setTenantLogo] = useState("");
  //

  //
  useEffect(() => {
    const fetchTenantData = async () => {
      try {
        setLogoLoading(true);
        // console.log("debug");
        const response = await getTenant(tenantUUID);
        console.log(response);
        setTenantName(response.data[0].name);
        setTenantLogo(response.data[0].logo);
      } catch (err: any) {
        console.error(err);
        setLogoLoading(false);
        notify_error(`Message:${err.message}`);
        //throw err;
      } finally {
        setLogoLoading(false);
      }
    };

    fetchTenantData();
  }, []);
  //
  const navigate = useNavigate();
  //
  const notify_error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = async (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState<string>("");
  //
  const HandleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      var call: any = await authSignIn({
        // tenantID: tenantId,
        tenantUUID: tenantUUID,
        email: Email,
        password: Password,
      });
      console.log(call);
      if (!call) {
        notify_error("Server error");
      } else {
        await notify_success(call.data.message);
        // navigate("/landing", { replace: true });
        setTimeout(() => {
          navigate("/landing", { replace: true });
        }, 2000); // Delay navigation to allow the toast to display
      }
    } catch (err: any) {
      console.log(err);
      if (!err.response.data) {
        notify_error(err.message);
      } else if (err.response.data) {
        notify_error(err.response.data || err);
      } else {
        notify_error(err);
      }
      // notify(err.response.data || err);
      // notify(err);
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };
  //
  //
  return (
    <div className="signIn">
      <ToastContainer />
      <div className="signIn_Form_div">
        {/* <div className="signin_container"> */}
        <div className="signin_company">
          <Spin spinning={logoLoading}>
            <img className="logo" src={TenantLogo || logo} alt="logo"></img>
          </Spin>
          <h4>{TenantName || "Company Name"}</h4>
        </div>
        <div>
          <Spin spinning={loading}>
            <form onSubmit={HandleSignIn} className="users_signin_form">
              <label>
                Email:
                <input
                  className="emailSignin"
                  type="email"
                  placeholder="example@example.com"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                ></input>
              </label>

              <label>
                Password:
                {/* <input
                  type="password"
                  placeholder="xxxxxxxx"
                  onChange={(event) => setPassword(event.target.value)}
                  required
                ></input> */}
                <Input.Password
                  className="passwordinput"
                  placeholder="xxxxxxxx"
                  onChange={(event) => setPassword(event.target.value)}
                  required
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </label>
              <div className="signinbtn_container">
                <button className="loginbtn" type="submit">
                  Log in
                </button>
                <div className="sif1">
                  <Popover title="Comming Soon!" trigger="click">
                    <h5>Forgot Password?</h5>
                  </Popover>
                </div>
              </div>
            </form>
          </Spin>
        </div>
        <div className="Sigin_footer">
          <div>
            <h5>Admin Account</h5>
            <label>Email: admin@test.com </label>
            <label>Password: password123</label>
          </div>
          <div className="sif2">
            <h5>Developed By Kirubel Jalleta</h5>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SignIn;
